<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>

      <v-card outlined class="mt-2">
        <v-toolbar flat color="grey lighten-3" height="auto">
          <v-list-item-content>
            <v-row align="center">
              <v-col>
                {{ litter.name }}
              </v-col>
              <v-col>
                <v-btn
                  x-small
                  depressed
                  color="red lighten-4 red--text"
                  v-if="litter.status == 'Failed'"
                >
                  {{ litter.status }}
                </v-btn>
                <v-btn
                  x-small
                  depressed
                  color="green lighten-4 green--text"
                  v-else
                >
                  {{ litter.status }}
                </v-btn>

                <v-btn
                  bottom
                  x-small
                  depressed
                  @click="downloadPdfPregnacy"
                  :loading="pdfPregnacy"
                  color="orange lighten-4 orange--text ml-2"
                  v-if="litter.status == 'Due' || litter.status == 'Born'"
                >
                  Pregnancy Schedule
                </v-btn>
              </v-col>

              <v-col align="right">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      v-on="on"
                      depressed
                      color="blue lighten-4 blue--text"
                      class="mr-2"
                      :href="getLitterUrl(litter.unique_id)"
                      target="_blank"
                    >
                      <v-icon small>mdi-monitor-dashboard</v-icon>
                    </v-btn>
                  </template>
                  <span>View Hidden Litter Page</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-list-item-content>

          <div></div>

          <template v-slot:extension>
            <v-tabs dark background-color="grey darken-2">
              <v-tab
                :to="{
                  name: `module-lhl-litters-individual`,
                }"
                >Basic Details</v-tab
              >
              <v-tab
                :to="{
                  name: `module-lhl-litters-individual-puppies`,
                }"
                >Puppies/Applications</v-tab
              >

              <v-tab
                :to="{
                  name: `module-lhl-litters-individual-record`,
                }"
                >Breeding Record</v-tab
              >
              <v-tab
                :to="{
                  name: `module-lhl-litters-individual-clp`,
                }"
                >Clp</v-tab
              >
              <v-tab
                :to="{
                  name: `module-lhl-litters-individual-visits`,
                }"
                >Visits</v-tab
              >
              <v-tab
                :to="{
                  name: `module-lhl-litters-individual-weight`,
                }"
                >Weight</v-tab
              >

              <v-tab
                :to="{
                  name: `module-lhl-litters-individual-website`,
                }"
                >Website</v-tab
              >
            </v-tabs>
          </template>
        </v-toolbar>

        <v-divider></v-divider>
        <div class="pa-5">
          <router-view></router-view>
        </div>
      </v-card>

      <v-row>
        <v-col cols="6">
          <v-card outlined class="mt-2">
            <v-tabs
              background-color="grey lighten-3"
              v-model="tab"
              @change="onTabChange"
            >
              <v-tab v-for="item in items" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="currentTab">
              <v-tab-item>
                <v-card>
                  <v-card-text
                    ><v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-card outlined class="mb-6">
                            <v-card-title
                              class="d-flex justify-start align-center grey lighten-3"
                            >
                              <div class="flex-grow-1">Management Tasks</div>
                            </v-card-title>
                            <v-data-table
                              :headers="tableHeadersTasks"
                              :items="tasks"
                              no-data-text="No Tasks"
                              :items-per-page="-1"
                            >
                              <template v-slot:item.task="{ item }">
                                {{
                                  item.task.name.replace(
                                    "{title}",
                                    litter.dam.name
                                  )
                                }}
                              </template>

                              <template v-slot:item.complete_by_date="{ item }">
                                {{ formatDate(item.complete_by_date) }}
                              </template>
                              <template v-slot:item.status="{ item }">
                                <v-switch
                                  v-model="item.status"
                                  @click="openSwitch(item)"
                                ></v-switch>
                              </template>
                            </v-data-table>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-card outlined class="mb-6">
                            <v-card-title
                              class="d-flex justify-start align-center grey lighten-3"
                            >
                              <div class="flex-grow-1">Admin Tasks</div>
                            </v-card-title>
                            <v-data-table
                              :headers="tableHeadersTasks"
                              :items="tasks_admin"
                              no-data-text="No Tasks"
                              :items-per-page="-1"
                            >
                              <template v-slot:item.task="{ item }">
                                {{
                                  item.task.name.replace(
                                    "{title}",
                                    litter.dam.name
                                  )
                                }}
                              </template>

                              <template v-slot:item.complete_by_date="{ item }">
                                {{ formatDate(item.complete_by_date) }}
                              </template>
                              <template v-slot:item.status="{ item }">
                                <v-switch
                                  v-model="item.status"
                                  @click="openSwitch(item)"
                                ></v-switch>
                              </template>
                            </v-data-table>
                          </v-card>
                        </v-col>
                      </v-row> </v-container
                  ></v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-card outlined class="mb-6">
                            <v-card-title
                              class="d-flex justify-start align-center grey lighten-3"
                            >
                              <div class="flex-grow-1">Operations Tasks</div>
                            </v-card-title>
                            <v-data-table
                              :headers="tableHeadersTasks"
                              :items="tasks_operation"
                              no-data-text="No Tasks"
                              :items-per-page="-1"
                            >
                              <template v-slot:item.task="{ item }">
                                {{
                                  item.task.name.replace(
                                    "{title}",
                                    litter.dam.name
                                  )
                                }}
                              </template>

                              <template v-slot:item.complete_by_date="{ item }">
                                {{ formatDate(item.complete_by_date) }}
                              </template>
                              <template v-slot:item.status="{ item }">
                                <v-switch
                                  v-model="item.status"
                                  @click="openSwitch(item)"
                                ></v-switch>
                              </template>
                            </v-data-table>
                          </v-card>
                        </v-col>
                      </v-row> </v-container
                  ></v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card outlined class="mt-2">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Pupdates</div>

              <v-btn
                small
                depressed
                color="green lighten-4 green--text"
                @click="$refs.pupdatesForm.openForm()"
              >
                Announce a New Pupdate
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>

            <v-data-table
              :headers="tableHeaders"
              :items="litter.pupdates"
              no-data-text="There has been no Pupdates sent."
              :items-per-page="-1"
            >
              <template v-slot:item.send_at="{ item }">
                {{ formatDateTime(item.send_at, true) }}
              </template>

              <template slot="item.index" slot-scope="props">
                {{ props.index + 1 }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  x-small
                  depressed
                  class="mr-2"
                  color="green lighten-4 green--text"
                  @click="displayMessage(item)"
                >
                  <v-icon x-small>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      scrollable
      v-model="viewMessage.dialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline">Message</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4">
          <div v-html="viewMessage.pupdate.message"></div
        ></v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <PupdatesForm ref="pupdatesForm" />
  </div>
</template>

<script>
import PupdatesForm from "@/modules/lhl/views/litters/components/pupdates/PupdatesForm";

export default {
  components: { PupdatesForm },
  data() {
    return {
      viewMessage: {
        dialog: false,
        pupdate: {},
        loading: false,
      },
      breadcrumbs: [
        {
          text: "Lomond Hills Labradoodles",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Litters",
          disabled: false,
          exact: true,
          to: {
            name: "module-lhl-litters",
            params: { id: this.$route.params.id },
          },
        },
      ],

      tableHeadersTasks: [
        { text: "Name", value: "task" },
        { text: "Status", value: "status" },
        { text: "For", value: "complete_by_date" },
      ],
      fields: {
        status: false,
      },
      snackbar: {
        open: false,
        text: "",
        timeout: 4000,
      },
      tab: null,
      openDelete: {
        dialog: false,
        item: null,
        model: {},
        loading: false,
      },

      tableHeaders: [
        { text: "#", value: "index" },
        { text: "Date", value: "send_at" },
        { text: "Sent By", value: "user.full_name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      pdfLoading: false,
      pdfPregnacy: false,
      pdfLoading1: false,
      pdfLoading2: false,
      currentTab: null,
      items: ["Management Tasks", "Admin Tasks", "Operations Tasks"],
    };
  },

  computed: {
    tasks() {
      let litter = this.$store.getters["lhl/litters/get"];

      let tasks = litter.tasks.filter((c) => {
        return c.task.task_category == 7;
      });

      return tasks;
    },

    tasks_operation() {
      let litter = this.$store.getters["lhl/litters/get"];

      let tasks = litter.tasks.filter((c) => {
        return c.task.task_category == 6;
      });

      return tasks;
    },

    tasks_admin() {
      let litter = this.$store.getters["lhl/litters/get"];

      let tasks = litter.tasks.filter((c) => {
        return c.task.task_category == 5;
      });

      return tasks;
    },

    litters() {
      let litters = this.$store.getters["lhl/litters/all"];

      return litters;
    },

    litter() {
      return this.$store.getters["lhl/litters/get"];
    },
  },

  methods: {
    getLitterUrl(unique_id) {
      return process.env.VUE_APP_LHL_FORMS_URL + "/litter-page/" + unique_id;
    },

    onTabChange() {
      this.currentTab = this.tab;
    },

    displayMessage(pupdate) {
      this.viewMessage.pupdate = pupdate;
      this.viewMessage.dialog = true;
    },

    resetDelete() {
      this.viewMessage.dialog = false;
      this.viewMessage.pupdate = {};
      this.viewMessage.loading = false;
    },

    openSwitch(task) {
      this.loading = true;
      this.errors = {};

      this.fields.status = task.status ? 1 : 0;

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
        taskId: task.id,
      };

      this.$store
        .dispatch("lhl/litters/saveTaskStatus", payload)
        .then(() => {})
        .catch();
    },

    downloadPdfPregnacy() {
      const appId = this.$route.params.id;

      const litterId = this.$route.params.litterId;
      this.pdfPregnacy = true;

      this.$store
        .dispatch("lhl/litters/downloadPdfPregnacy", {
          appId,
          litterId,
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);

          this.pdfPregnacy = false;
        })
        .catch(() => {
          this.pdfPregnacy = false;
        });
    },
  },
};
</script>
